<template>
  <v-card
    class="bl_imageCard"
    max-width="100vw"
  >
    <v-img
      class="align-end"
      
      width="100%"
      min-width="250px"
      min-height="300px"
      max-height="300px"
      :src="img_url"
      :alt="title"
      @click="toggle"
    >
      <v-card-actions>
        <v-btn
          text
          color="teal accent-4"
        >
        More
          <v-icon>{{ reveal ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
        </v-btn>
      </v-card-actions>
    </v-img>

    <v-expand-transition>
      <v-card
        v-if="reveal"
        @click="toggle"
        elevation="10"
        :class="{'uq_isOpen': reveal}"
        class="transition-fast-in-fast-out v-card--reveal bl_card_expantion"
      >
        <v-card-title class="text-subtitle-2 text--primary">{{title}}</v-card-title>
        <v-card-subtitle >{{name}}</v-card-subtitle>

        <v-card-text class="text--primary">
          <div v-html="caption"></div>
        </v-card-text>
<!--
        <ul v-for="list in tag_cathegory" v-bind:key="list.index" class="bl_card_keywords">
          <li>{{list}}</li>
        </ul>
-->
        <v-card-actions class="bl_cardActtions__flexWrap">
          <span class="bl_cardActtions__flexWrap_header">販売中のサイト</span>
          <v-btn
            v-if="adb_url"
            :href="adb_url"
            target="_blank" rel="noopener noreferrer"
            color="primary" x-small text
          >
            Adobe Stock
          </v-btn>

          <v-btn
            v-if="px_url"
            :href="px_url"
            target="_blank" rel="noopener noreferrer"
            color="primary" x-small text
          >
            PIXTA
          </v-btn>
          <v-btn
            v-if="istk_url"
            :href="istk_url"
            target="_blank" rel="noopener noreferrer"
            color="primary" x-small text
          >
            iStock
          </v-btn>
          <v-btn
            v-if="ss_url"
            :href="ss_url"
            target="_blank" rel="noopener noreferrer"
            color="primary" x-small text
          >
            ShutterStock
          </v-btn>
          <v-btn
            v-if="imgm_url"
            :href="imgm_url"
            target="_blank" rel="noopener noreferrer"
            color="primary" x-small text
          >
            imagemart
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-expand-transition>
  </v-card>
</template>

<script>
export default {
  name: 'ImageCard',
  props: {
    title: {type: String},
    name: {type: String},
    img_url: {type: String},
    caption: {type: String},
    adb_url: {type: String},
    px_url: {type: String},
    ss_url: {type: String},
    imgm_url: {type: String},
    istk_url: {type: String},
    tag_type: {type: Object},
    tag_cathegory: {type: Array},
    is_open:{type: Boolean},
  },
  computed:{
  },
  methods:{
    toggle(){
      this.reveal = !this.reveal
    }
  },
  data: () => ({
    reveal: false,
  }),
}
</script>

<style scoped>
.bl_imageCard{
  display: flex;
  flex-direction: row;
  overflow: hidden;
  padding: 0;
}
.bl_card_keywords{
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
}
.bl_card_keywords{
  display: inline-flex;
  font-size: 12px;
  color: #777;
}
.bl_cardActtions__flexWrap{
  display: flex;
  flex-wrap: wrap;
  border-top: 1px solid #ccc;
  margin-top: 8px;
}
.bl_cardActtions__flexWrap_header{
  display: inline-flex;
  width: 100%;
  margin-left: 8px;
  margin-right: auto;
  font-size: 12px;
}
.bl_cardActtions__flexWrap>.v-btn{
  margin: 0px 8px;
  text-transform: none;
}

.v-card.uq_isOpen{
  height: 100%;
  background-color: rgba(255,255,255,.9);
}

.bl_card_expantion{
  display: grid;
  grid-template-rows: 1fr auto 6rem;
  padding: 0;
}
.v-card--reveal {
  bottom: 0;
  opacity: 1 !important;
  position: absolute;
  width: 100%;
}
</style>

