import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue'),
    meta: { title: 'デザイン事務所 interemit' }
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue'),
    meta: { title: 'about | interemit' }
  },
  {
    path: '/works',
    name: 'works',
    component: () => import('../views/Works.vue'),
    meta: { title: 'works | interemit' },
  },
  {
    path: '/works/detail',
    name: 'works-detail',
    component: ()=> import('../views/works/WorksDetail.vue'),
    meta: { title: 'works-list | interemit' },
  },
  {
    path: '/works/oomurojo',
    name: 'works-oomurojo',
    component: ()=> import('../views/works/Oomurojo.vue'),
    meta: { title: 'works-list | interemit' },
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('../views/Contact.vue'),
    meta: { title: 'contact | interemit' }
  },
  {
    path: '/contact/thanks',
    name: 'contactThanks',
    component: () => import('../views/ContactThanks.vue'),
    meta: { title: 'お問い合わせを受け付けました' }
  },
  {
    path: '/class-index',
    name: 'classIndex',
    component: () => import('../views/ClassIndex.vue'),
    meta: { title: 'デジタルお絵描きワークショップ' }
  },
  {
    path: '/class-past',
    name: 'classPast',
    component: () => import('../views/ClassPast.vue'),
    meta: { title: 'デジタルお絵描きワークショップ - 過去に開催したワークショップ' }
  },
  {
    path: '/privacy',
    name: 'PrivacyPolicy',
    component: () => import('../views/PrivacyPolicy.vue'),
    meta: { title: 'プライバシーポリシー' }
  },
  /* Page not found の場合 */
  { path: '*',
    component: () => import('../views/NotFound.vue')
  }
]

/*
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
*/
const DEFAULT_TITLE = 'inte'
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  //スクロール位置を指定
  scrollBehavior(to, from, savedPosition){
    if(savedPosition){
      //savedPositionがあるとvueデフォルトのスクロール挙動になるようです（スクロール位置がページ遷移後も保存される）
      return savedPosition
    } else if (to.hash) {
      const position = {}
      position.selector = to.hash
      //ページ内リンクがヘッダーにかかって見えなくならないようオフセット
      position.offset = { y: 120 }
      return position
    } else {
      //savedPositionがなく、かつ、router-linkで＃を使用していない場合はスクロール位置がtopになります。
      return { x:0, y:0 }
    }
  },
})
router.afterEach((to) => {
  document.title = to.meta.title || DEFAULT_TITLE
})
export default router
