<template>
<v-app-bar
      app
      color="rgba(255,255,255,.9)"
      dense
    >
    <v-app-bar-nav-icon>
        <v-img
          alt="interemit Logo"
          class="shrink mr-2"
          contain
          src="/img/common/logo_inline@2x.png"
          transition="scale-transition"
          width="40"
        />
    </v-app-bar-nav-icon>
    <v-toolbar-title class="el_header_title primary--text">interemit</v-toolbar-title>

    <template v-slot:extension>
        <v-tabs centered center-active active-class show-arrows>
            <v-tab><router-link to="/">home</router-link></v-tab>
            <v-tab><router-link to="/works">works</router-link></v-tab>
            <v-tab><router-link to="/about">about</router-link></v-tab>
            <v-tab><router-link to="/class-index">workshop</router-link></v-tab>
            <v-tab><router-link to="/contact">contact</router-link></v-tab>
        </v-tabs>
    </template>

</v-app-bar>
</template>

<script>
export default {
  name: 'BlHeader',
  props: {
    msg: String
  },
  computed:{
    getScrollY(){
        return this.$store.getters['view/getScrollY']
    },
    getWindowWidth(){
        if(this.$store.getters['view/getWindowWidth'] < 1280){
            return this.$store.getters['view/getWindowWidth']
        }else{
          return 1280
        }
    },
    getWindowHeight(){
      return this.$store.getters['view/getWindowHeight']
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.bl_header{
    border-bottom: 1px solid rgba(0,0,0,0);
}
.ly_header{
    width: 100%;
    height: 64px;
    top: 0;
    position: fixed;
    z-index: 500;
}
.ly_header_inner{
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    margin-right: auto;
    margin-left: auto;
    z-index: 510;
}
.fixed {
    transition: all 0.5s ease-out;
}
.fixed.ly_header{
    height: 44px;
}
.fixed.bl_header:before{
    height: 44px;
}

/* foldBox */
header nav >>> .bl_foldBox .bl_foldBox_btnOpen{
    display: inline-flex;
    color: rgb(0, 73, 95);
    background-color: transparent;
    font-size: 12px;
    border: none;
    border-radius: 0;
    border-right: 1px solid #ccc;
}
header nav >>> .bl_foldBox .bl_foldBox_btnOpen:hover,
header nav >>> .bl_foldBox .bl_foldBox_btnOpen:focus{
    color: rgb(0, 73, 95);
    background-color: transparent;
    border-color: #ccc;
    border-width: 1px;
    box-shadow: none;
}
header nav >>> .bl_foldBox .bl_foldBox_cont{
    width: 100%;
    position: absolute;
}
</style>

