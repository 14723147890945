import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#14B3E3",
        secondary: "#ff5722",
        accent: "#f44336",
        error: "#3f51b5",
        warning: "#607d8b",
        info: "#cddc39",
        success: "#009688",
      },
    },
  },
});
