<template>
    <div class="tempDefault">
        <bl-header></bl-header>
        <slot></slot>
    </div>
</template>

<script>

export default {
  name: 'TempDefault',
  components: {

  }
}
</script>

<style scoped>
.tempDefault{
    padding-top: 64px;
}
.tempDefault >>> .bl_container__default{
    max-width: 1200px;
    margin: 0 auto;
}
</style>
