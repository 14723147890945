import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import './sass/variable.scss'

Vue.config.productionTip = false

//molecules
import ImageCard from '@/components/molecules/ImageCard.vue'

//organisms
import BlHeader from '@/components/organisms/BlHeader.vue'


//templates
import TempDefault from '@/components/templates/TempDefault.vue'




Vue.component('image-card', ImageCard)
Vue.component('bl-header', BlHeader)
Vue.component('temp-default', TempDefault)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
