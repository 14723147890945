<template>
  <v-app>
    <bl-header />
    <router-view/>

    <v-footer>
      <v-row>
        <v-col
          class="text-center"
          cols="12">
            &copy; 2021 interemit
        </v-col>
        <v-col cols="12"
          align="center"
          justify="center"
          class="pa-0">
          <p class="pa-0 ma-0">当サイト内のすべての絵と文の無断転載はご遠慮ください。転載ご希望の際は<router-link to="/contact" style="color:#999;"> お問い合わせ </router-link>よりご連絡願います。</p>
        </v-col>
        <v-col cols="12"
          align="center" 
          justify="center">
          <router-link to="privacy">プライバシーポリシー</router-link>
        </v-col>
      </v-row>
    </v-footer>
  </v-app>
</template>

<script>

export default {
  components: {  },
  name: 'App',

  data: () => ({
    //
  }),
};
</script>


<style lang="scss">
  @import '~vuetify/src/styles/styles.sass';

html * {
  box-sizing:border-box;
}

body{
  width: 100%;
  min-height: 100vh;
  margin: 0;
  font-family: "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  line-height: 1.5;
}
/* link */
a{
  &:link {
    color: #111;
    text-decoration:none;
  }
  &:visited {
    color: #333;
  }
  &:hover {
    color: #666;
    font-weight: bolder;
  }
  &:active {
    color: #555;
  }
}

/*
---------------------------
入力部品系リセット
---------------------------
 */
/* ボタンなどの青枠を消す */
input[type="button"],
textarea,
select,
button {
  outline: none;
}
/*
---------------------------
 レイアウト
---------------------------
入れ子にして使用しないこと。
 */
.ly_cont{
  max-width: 1230px;
  margin: 0 auto;
  padding: 60px 16px;
}

/* ly_all__～は入れ子で使ってもok */
.ly_all__flexCont{
    display: flex;
}
.ly_all__flexCont:not(.ly_all__flexCont__column)>*{
    margin-right: 2px;
}
.ly_all__flexCont.ly_all__flexCont__column{
    flex-direction: column;
}
.bl_buttonWrapper{
  display: flex;
  align-items: center;
  justify-content: center;
}
.bl_headline{
  margin: 32px 0;
}
.bl_headline *[class^="text"]{
  margin: 32px 0 16px;
}
/*
---------------------------
  スタイル
---------------------------
 */
/* 小さなパーツ=iconフォントで代替可能かも */
.el_chevron_bottom{
  display: inline-flex;
  width: 1em;
  height: 1em;
  transition: all 1s ease;
}
.el_chevron_bottom:after{
  content: "";
  display: inline-block;
  width: .6em;
  height: .6em;
  border-right: 1px solid rgb(0, 73, 95);
  border-bottom : 1px solid rgb(0, 73, 95);
  transform: rotateZ(45deg);
}
</style>
